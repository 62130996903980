import React, { useState } from "react";
import '../../css/blog.css';


export default function Post({ post: {title, body, img, author, tags },  index, handleBlogSelect }) {
    

    return (
        <div className="post" onClick={() => {handleBlogSelect(index)}}>
            <div className="postImg" style={{backgroundImage: `url(${img})`}}>

            </div>
            <div className="postDetails">
                <h2 className="postTitle">{title}</h2>
                <p className="postBody">{body}</p>
                <p className="postAuthor">Written By: {author}</p>
                <div className="tagsContainer">
                    {tags.map((tag, index) => (
                        <p key={index}>{tag}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}