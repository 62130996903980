import { useState, useEffect } from "react";
import OpenAI from 'openai';
import { firestore } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import './newDemo.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowRightLong, faArrowLeftLong);

export default function StoryGenPhonemic() {
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [objectives, setObjectives] = useState([]);
  const [filteredObjectives, setFilteredObjectives] = useState([]);
  const [storedObjectives, setStoredObjectives] = useState([]); // Store the initial list of objectives
  const [textLoading, setTextLoading] = useState(false);
  const [sentences, setSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [openAIOutput, setOpenaiOutput] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [storyTopic, setStoryTopic] = useState('');

  // Fetch only objectiveNum, phonemeFocus, objectiveType, and objectiveNotes initially
  const fetchObjectives = async () => {
    try {
      const objectivesCollectionRef = collection(firestore, 'curriculums', 'UFLI Foundations', 'objectives');
      const q = query(objectivesCollectionRef);
      const objectivesSnapshot = await getDocs(q);
      const fetchedObjectives = objectivesSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          objectiveNum: data.objectiveNum,
          phonemeFocus: data.phonemeFocus,
          objectiveType: data.objectiveType, // Include objectiveType
          objectiveNotes: data.objectiveNotes || '', // Ensure objectiveNotes exist, fallback to empty string
        };
      });

      const sortedObjectives = fetchedObjectives.sort((a, b) => a.objectiveNum - b.objectiveNum);
      setObjectives(sortedObjectives);
      setFilteredObjectives(sortedObjectives);
      setStoredObjectives(sortedObjectives); // Store the initial objectives
    } catch (error) {
      console.error("Error fetching objectives:", error);
    }
  };

  useEffect(() => {
    fetchObjectives();
  }, []);

  // Handle search input change to filter objectives dynamically
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      // If search term is cleared, reset the filteredObjectives to all objectives
      setFilteredObjectives(storedObjectives);
    } else {
      // Filter objectives based on phonemeFocus
      const filtered = storedObjectives.filter(objective =>
        objective.phonemeFocus.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredObjectives(filtered);
    }
  };

  // Handle objective selection from dropdown
  const handleSelectObjective = async (event) => {
    const selectedValue = event.target.value;
    const selectedObjective = storedObjectives.find(
      (obj) => `${obj.objectiveNum}: ${obj.phonemeFocus}` === selectedValue
    );

      // Show alert for early objectives (1-10)
  if (selectedObjective && selectedObjective.objectiveNum >= 1 && selectedObjective.objectiveNum <= 10) {
    alert("Please note that early objectives do not provide enough phonemic structures to construct a whole story, and your generated story may not be decodable.");
  }

    if (selectedObjective) {
      setSelectedObjective(selectedObjective); // Set selected objective
      setSearchTerm(`${selectedObjective.objectiveNum}: ${selectedObjective.phonemeFocus}`); // Set input to the selected value

      // Fetch the objectiveNotes for the selected objective and all preceding objectives
      try {
        const objectivesCollectionRef = collection(firestore, 'curriculums', 'UFLI Foundations', 'objectives');
        const q = query(objectivesCollectionRef, where("objectiveNum", "<=", selectedObjective.objectiveNum));
        const newObjectivesSnapshot = await getDocs(q);
        const updatedObjectives = newObjectivesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            objectiveNum: data.objectiveNum,
            phonemeFocus: data.phonemeFocus,
            objectiveType: data.objectiveType,
            objectiveNotes: data.objectiveNotes || '', // Ensure objectiveNotes exist
          };
        });

        const sortedUpdatedObjectives = updatedObjectives.sort((a, b) => a.objectiveNum - b.objectiveNum);
        setObjectives(sortedUpdatedObjectives);
      } catch (error) {
        console.error("Error fetching objectiveNotes:", error);
      }
    } else {
      // If no objective is selected (i.e., input is cleared), reset selectedObjective and searchTerm
      setSelectedObjective(null);
      setSearchTerm('');
      setFilteredObjectives(storedObjectives); // Reset the filtered objectives
    }
  };

  // Handle story generation
  const handleStorySubmit = async () => {
    if (!selectedObjective) {
      console.error("Please select an objective before generating a story.");
      return;
    }

    setTextLoading(true);
    const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

    // Fetch selected objective's phonemeFocus and objectiveNotes for the prompt
    const phonemeFocus = selectedObjective?.phonemeFocus;
    const objectiveNotes = selectedObjective?.objectiveNotes;

    // Combine phonemeFocus and objectiveNotes from all objectives up to the selected one
    const relevantObjectives = storedObjectives.filter(
      (obj) => obj.objectiveNum <= selectedObjective.objectiveNum && obj.objectiveType === "New"
    );
  
    // Now build allPhonemeFocuses with both objectiveNum and phonemeFocus
    const allPhonemeFocuses = relevantObjectives
      .map((obj) => `${obj.objectiveNum}: ${obj.phonemeFocus}`)
      .join(", ");
  
    // Build allObjectiveNotes, only including objectiveNum if notes are present
    const allObjectiveNotes = relevantObjectives
      .map((obj) => obj.objectiveNotes ? `${obj.objectiveNum}: ${obj.objectiveNotes}` : '')
      .filter(note => note !== '') // Remove empty strings where no notes exist
      .join(" ");

    const promptTemplate = `
      Please write a short, simple, and appropriate story for K-2 aged children that uses the following phonemic structures "${allPhonemeFocuses}".
      Do not use any structures in the story that are not expressly provided above.
      The story itself, however, should have a high concentration of this phonemic structure: "${phonemeFocus}".
      The story should be approximately 40 words long. Only include the contents of the story in your output.
      Please do not use any dialogue in the story, and avoid punctuation other than periods and commas.
      Include the concepts explained in the objective notes: "${allObjectiveNotes}". The objective number preceding the notes corresponds to each objective number provided above, to better explain how each phoneme focus is used.
      ${storyTopic ? `The story should also try to incorporate the following topic, if possible: "${storyTopic}".` : ''}
    `;
    console.log(promptTemplate)
    const stream = await openai.beta.chat.completions.stream({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: promptTemplate }],
      stream: true,
    });

    let generatedStory = '';

    stream.on('content', (delta) => {
      generatedStory += delta;
      setOpenaiOutput(generatedStory.trim());
      const storySentences = generatedStory.split('.').filter(sentence => sentence.trim() !== '').map(sentence => sentence.trim() + '.');
      setSentences([...storySentences]);
      setTextLoading(false);
    });
  };

    // Clear the story output and inputs
    const handleClear = () => {
      setOpenaiOutput('');
      setSentences([]);
      setSelectedObjective(null);
      setSearchTerm('');
      setStoryTopic('');
      setCurrentSentenceIndex(0);
      setTextLoading(false);
    };

  return (
    <div className="newDemo">
      <div className="demoStructure">
        <h2 className="decodableGenerator">Decodable Generator</h2>

        {/* Hardcoded Curriculum Dropdown */}
        <p>Select Curriculum</p>
        <span>More curricula/scope and sequences on the way!</span>
        <select className='demoInput' value="UFLI Foundations">
          <option value="UFLI Foundations">UFLI Foundations</option>
        </select>

        {/* Objectives ComboBox */}
        <p>Select Objective</p>
        <input
          type="text"
          className="demoInput"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Type to search and select objective"
          list="objectives"
          onBlur={handleSelectObjective} // Use onBlur to handle when selection is made
        />
        <datalist id="objectives">
          {filteredObjectives.map(objective => (
            <option key={objective.id} value={`${objective.objectiveNum}: ${objective.phonemeFocus}`} />
          ))}
        </datalist>

        {/* Story Topic Input */}
        <p>Story Topic (Optional)</p>
        <span>Please note that including specific words/terms may decrease decodability of story.</span>
        <input
          type="text"
          className="demoInput"
          value={storyTopic}
          onChange={(e) => setStoryTopic(e.target.value)}
          placeholder="Enter a topic for the story"
        />

        <div id="buttonContainer">
        <button className='demoButton' onClick={handleStorySubmit}>Generate</button>
        <button className='clearButton' onClick={handleClear}>Clear</button>
        </div>
      </div>

      {textLoading ? (
        <div className="loadingMessage">Generating your personal story!</div>
      ) : (
        openAIOutput && (
          <div className='demoContent'>
            <div className="demoStoryArea">
              <p>{sentences[currentSentenceIndex]}</p>
              <div>
                <button onClick={() => setCurrentSentenceIndex((prev) => Math.max(prev - 1, 0))} disabled={currentSentenceIndex === 0}>
                  <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" />
                </button>
                <button onClick={() => setCurrentSentenceIndex((prev) => Math.min(prev + 1, sentences.length - 1))} disabled={currentSentenceIndex === sentences.length - 1}>
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
                </button>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
