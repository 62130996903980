import React, { useState, useEffect } from 'react';
import './studentPopup.css';

// Function to normalize the word by removing non-letter characters except periods and converting to lowercase
const normalizeWord = (word) => {
    return word.replace(/[^\w\s]|_/g, '').toLowerCase();
};

// Function to clean up the word by removing non-letter characters except periods
const cleanWord = (word) => {
    return word.replace(/[^\w\s]|_/g, ''); // Keeps periods in the display
};

const LessonDetailsDisplay = ({ 
    lesson, 
    setGraded,
    notes,
    setNotes,
    erroredWords,
    setErroredWords,
    isEditing,
    percentageCorrect,
    setPercentageCorrect
    }) => {


    // Ensure notes is properly initialized from lesson.notes
    useEffect(() => {
        if (lesson && lesson.notes) {
            setNotes(lesson.notes);
        }
    }, [lesson, setNotes]);




    
    useEffect(() => {
        if (lesson && lesson.erroredWords) {
            // Ensure that each erroredWord has both word and saidInstead fields
            const updatedErroredWords = lesson.erroredWords.map(erroredWord => ({
                ...erroredWord,
                word: erroredWord.word || erroredWord.originalWord, // Ensure word is preserved
                saidInstead: erroredWord.saidInstead || '' // Ensure saidInstead is initialized
            }));
            setErroredWords(updatedErroredWords);
        }
    }, [lesson, setErroredWords]);

    




    // Check if the lesson is graded and set the state accordingly
    useEffect(() => {
        if (lesson && lesson.graded) {
            setGraded(lesson.graded);
        }
    }, [lesson, setGraded]);

    const passValue = lesson.pass;

    // Flatten the story content into a single array of words with their positions
    const getFlattenedWords = (storyContent) => {
        if (!Array.isArray(storyContent)) {
            return [];
        }
        return storyContent.flatMap((sentence, sentenceIndex) => 
            sentence.split(/\s+/).map((word, wordIndex) => ({
                word: cleanWord(word), // Cleaned word for display
                originalWord: word, // Original word including punctuation
                index: `${sentenceIndex}-${wordIndex}`, // Unique index for each word
                normalized: normalizeWord(word) // Normalized version of the word
            }))
        );
    };

    // Handle word click to toggle error status
    const handleWordClick = (wordObj) => {
        if (!isEditing) {
            return;
        }
        if (wordObj.normalized) {
            setErroredWords(prevErroredWords => {
                const existingIndex = prevErroredWords.findIndex(item => item.index === wordObj.index);
                if (existingIndex > -1) {
                    // Remove word if already errored
                    return prevErroredWords.filter((_, index) => index !== existingIndex);
                } else {
                    // Add word if not errored
                    return [...prevErroredWords, { ...wordObj, saidInstead: '', showInput: false }];
                }
            });
        }
    };

    // Handle click on errored word in the list to toggle the input box
    const handleErroredWordClick = (index) => {
        if (isEditing) {
            setErroredWords(prevErroredWords => {
                return prevErroredWords.map(item =>
                    item.index === index ? { ...item, showInput: !item.showInput } : item
                );
            });
        }
    };

    // Handle input change for what the student said instead
    const handleSaidInsteadChange = (index, value) => {
        setErroredWords(prevErroredWords => {
            return prevErroredWords.map(item =>
                item.index === index ? { ...item, saidInstead: value } : item
            );
        });
    };

    // Confirm input and hide the text input
    const handleConfirmInput = (index) => {
        setErroredWords(prevErroredWords => {
            return prevErroredWords.map(item =>
                item.index === index ? { ...item, showInput: false } : item
            );
        });
    };

    // Calculate total words and correct words percentage
    const calculateScore = (storyContent) => {
        const words = getFlattenedWords(storyContent);
        const totalWords = words.length;
        const erroredWordIndexes = erroredWords.map(item => item.index);
        const correctWords = words.filter(wordObj => !erroredWordIndexes.includes(wordObj.index)).length;
        const percentageCorrect = totalWords > 0 ? (correctWords / totalWords) * 100 : 0;

        return { totalWords, correctWords, percentageCorrect: percentageCorrect.toFixed(2) };
    };

    // Use effect to update percentageCorrect in the parent when it's calculated
    useEffect(() => {
        console.log(erroredWords)
        const { percentageCorrect } = calculateScore(lesson.storyContent || []);
        setPercentageCorrect(percentageCorrect);  // Update parent state
    }, [lesson, erroredWords, setPercentageCorrect]);

    // Function to render story content with clickable words
    const renderStory = (storyContent) => {
        if (!Array.isArray(storyContent)) {
            return 'Invalid story content';
        }

        return storyContent.map((sentence, sentenceIndex) => (
            <div key={sentenceIndex} className="sentence">
                {sentence.split(/\s+/).map((word, wordIndex) => {
                    const wordIndexStr = `${sentenceIndex}-${wordIndex}`;
                    const isErrored = erroredWords.some(item => item.index === wordIndexStr);
                    
                    return (
                        <span
                            key={wordIndex}
                            onClick={() => handleWordClick({
                                word: cleanWord(word),
                                originalWord: word,
                                index: wordIndexStr,
                                normalized: normalizeWord(word)
                            })}
                            className={`clickable-word ${isErrored ? 'errored' : ''}`}
                        >
                            {word}{' '}
                        </span>
                    );
                })}
            </div>
        ));
    };

// Function to render the errored words list with input for what was said instead
const renderErroredWords = () => {
    console.log(erroredWords)
    return erroredWords.map((item, idx) => (
        <div key={idx} className="errored-word-item">
            <span className="errored-word-text" onClick={() => handleErroredWordClick(item.index)}>
                {/* Original word with red color and crossed out */}
                <s style={{ color: 'red' }}>{item.normalized}</s>
                
                {/* Display what the student said instead, if available */}
                {item.saidInstead && (
                    <span className="said-instead" style={{ fontStyle: 'italic' }}>
                        &rarr; {item.saidInstead}
                    </span>
                )}
            </span>
            
            {/* Conditionally show the input for editing what was said instead */}
            {item.showInput && (
                <div style={{ display: 'inline-block', marginLeft: '5px' }}>
                    <input
                        type="text"
                        value={item.saidInstead || ''}  // Empty string if not provided yet
                        onChange={(e) => handleSaidInsteadChange(item.index, e.target.value)}
                        placeholder="What did the student say?"
                        style={{ marginRight: '5px' }}  // Slight margin between input and button
                    />
                    <button 
                        onClick={() => handleConfirmInput(item.index)} 
                        style={{ backgroundColor: 'green', color: 'white' }}
                    >
                        ✔
                    </button>
                </div>
            )}
        </div>
    ));
};


    return (
        <div className="lessonDetailsDisplay">
            <div className='dataInnerContainer generatedStory' id='generatedStory'>
                <h3>Generated Story:</h3>
                {renderStory(lesson.storyContent || [])}
            </div>

            <div className='dataInnerContainer erroredWords'>
                <h3>Errors:</h3>
                {erroredWords.length > 0 ? renderErroredWords() : <p>No errors marked yet.</p>}
            </div>

            <div className='dataInnerContainer notes'>
                <h3>Lesson Notes:</h3>
                <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Add notes here..."
                />
            </div>

            <div className='dataInnerContainer score'>
                <h3>Score:</h3>
                <p>Total Words: {getFlattenedWords(lesson.storyContent || []).length}</p>
                <p>Correct Words: {calculateScore(lesson.storyContent || []).correctWords}</p>
                <p>Percentage Correct: {percentageCorrect}%</p>
            </div>

            <div className='dataInnerContainer audio'>
                <h3>Audio:</h3>
                <audio src={lesson.audio || ''} controls />
            </div>
        </div>
    );
};

export default LessonDetailsDisplay;
