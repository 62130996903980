import React, { useState } from "react";

export default function Pagination ({ postsPerPage, totalPosts, paginate }) {

    const [activePage, setActivePage] = useState(1);
    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++){
        pageNumbers.push(i);
    }

    const handlePageClick = (number) => {
        setActivePage(number);
        paginate(number);
    }

    return (
        <div>
            <ul className="pagination">
                {pageNumbers.map((number) => (
                    <li key={number} className={`pageItem ${activePage===number?"active":""}`}>
                        <a
                            href="#"
                            className="pageLink"
                            onClick={() => {
                                handlePageClick(number);
                                paginate(number);
                            }}>
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}