import React from 'react';

const ClearErroredWordsButton = ({ isEditing, handleClearErroredWordsClick }) => {
    return (
        <div className='clearErroredWordsButton'>
            {!isEditing ? (
                <div>
                </div>
            ) : (
                <button onClick={handleClearErroredWordsClick}>Clear Errored Words &#x2716;</button>
            )}
        </div>
    );
};

export default ClearErroredWordsButton;
