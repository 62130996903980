import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useParams, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import BabbleBotLogo from '../../images/babblebot.png';
import './teacherLibraryList.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(faXmark);

export default function TeacherLibraryList({ selectedStory, setSelectedStory }) {
  const [stories, setStories] = useState([]);
  const { teacherDocumentID } = useParams();
  const location = useLocation();

  const fetchStories = async () => {
    try {
      const storiesRef = collection(firestore, `users/${teacherDocumentID}/generatedStories`);
      const storiesSnapshot = await getDocs(storiesRef);
      const storiesList = storiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStories(storiesList);
    } catch (error) {
      console.error('Error fetching stories:', error);
    }
  };

  useEffect(() => {
    fetchStories();
  }, [teacherDocumentID]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const storyId = queryParams.get('storyId');
    if (storyId) {
      const story = stories.find(s => s.id === storyId);
      if (story) {
        setSelectedStory(story);
      }
    }
  }, [location.search, stories]);

  const selectStory = (story) => {
    setSelectedStory(story);
  };

  const closeModal = () => {
    setSelectedStory(null);
  };

  const deleteStory = async (storyId) => {
    try {
      await deleteDoc(doc(firestore, `users/${teacherDocumentID}/generatedStories`, storyId));
      setStories(stories.filter(story => story.id !== storyId));
      closeModal();
    } catch (error) {
      console.error('Error deleting story:', error);
    }
  };

  const exportPDF = () => {
    if (!selectedStory) return;

    const pdf = new jsPDF();

    // Adding the logo
    const img = new Image();
    img.src = BabbleBotLogo;
    img.onload = () => {
      pdf.addImage(img, 'PNG', pdf.internal.pageSize.getWidth() - 45, 15, 30, 30);

      // Adding the story title
      pdf.setFontSize(22);
      pdf.setFont('helvetica', 'bold');
      pdf.text(selectedStory.storyTitle, pdf.internal.pageSize.getWidth() / 2, 60, { align: 'center' });
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'normal');

      // Adding the story content
      const text = selectedStory.storyContent.split('\n').filter(line => line.trim() !== '');
      let y = 80;
      text.forEach(line => {
        pdf.text(line, 15, y, { maxWidth: pdf.internal.pageSize.getWidth() - 30 });
        y += 10;
      });

      // Adding the phonemic focus
      pdf.setFontSize(14);
      pdf.text('Phonemic Focus:', 15, y + 20);
      pdf.setFontSize(12);
      const phonemicFocusContent = selectedStory.phonemicFocus.map(phoneme => `${phoneme.phonemicTranslation} (${phoneme.englishTranslation})`).join(', ');
      pdf.text(phonemicFocusContent, 15, y + 30, { maxWidth: pdf.internal.pageSize.getWidth() - 30 });

      // Adding the date created
      const dateCreated = new Date(selectedStory.dateCreated.seconds * 1000).toLocaleDateString();
      pdf.text(`Date Created: ${dateCreated}`, 15, y + 50);

      pdf.save(`${selectedStory.storyTitle}.pdf`);
    };
  };

  return (
    <div className="libraryList">
      <ul>
        {stories.map(story => (
          <li id='books' style={{backgroundColor: ''}} key={story.id} onClick={() => selectStory(story)}>
            <div className='topHalfCard'>
              <div className='phonemeContainer'>
                {story.phonemicFocus.map((phoneme, index) => (
                  <p>{' ' + phoneme.englishTranslation}</p>
                ))}
              </div>
              <hr className='phonemeTitleDivider'/>
            </div>
            <p className='title'>{story.storyTitle}</p>
          </li>
        ))}
      </ul>

      {selectedStory && (
        <div className="modal">
          <div className="modalContent">
            <button className="closeButton" onClick={closeModal}><FontAwesomeIcon className='closeButtonIcon' icon="fa-solid fa-xmark" /></button>
            <h2>{selectedStory.storyTitle}</h2>
            <p>{selectedStory.storyContent}</p>
            <h4>Phonemic Focus:</h4>
            <ul>
              {selectedStory.phonemicFocus.map((phoneme, index) => (
                <li key={index}>
                  {phoneme.phonemicTranslation} ({phoneme.englishTranslation})
                </li>
              ))}
            </ul>
            <p>Date Created: {new Date(selectedStory.dateCreated.seconds * 1000).toLocaleDateString()}</p>
            <div>
              <button className="deleteButton" onClick={() => deleteStory(selectedStory.id)}>Delete</button>
              <button className="exportButton" onClick={exportPDF}>Export as PDF</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
