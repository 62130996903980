import React from 'react';

const EditSaveButtons = ({ isEditing, setIsEditing, handleSaveLessonChangesClick }) => {
    return (
        <div className='passFailBtns'>
            {!isEditing ? (
                <div>
                    <button onClick={() => setIsEditing(true)}>Edit Lesson</button>
                </div>
            ) : (
                <button onClick={handleSaveLessonChangesClick}>Save Changes &#x2713;</button>
            )}
        </div>
    );
};

export default EditSaveButtons;
