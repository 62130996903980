import React from 'react';

const PrevNextLessonButtons = ({ lessonsData, nextLesson, previousLesson, currentLessonIndex }) => {
    const currentLesson = lessonsData[currentLessonIndex];

    return (
        <div className='prevNextBtns'>
            {lessonsData.length > 0 && (
                <>
                    <button onClick={previousLesson}><p>&#x279C;</p></button>
                    <button onClick={nextLesson}><p>&#x279C;</p></button>
                </>
            )}
        </div>
    );
};

export default PrevNextLessonButtons;
