import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faTree, faHouse, faCity, faStore, faIgloo, faCampground, faDungeon } from '@fortawesome/free-solid-svg-icons';

import '../../css/componentStyles.css';

library.add( faSchool, faTree, faHouse, faCity, faStore, faIgloo, faCampground, faDungeon );


export default function SettingSelect({ onSelectSetting}) {
    
    // Function to handle button clicks
    const handleButtonClick = (event) => {
        onSelectSetting(event.target.value);
    };

    return(
        <div className='settingSelect' id='settingSelect'>
            <button className='settingBtn' value={'park'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-tree" />
                <div>Park</div>
            </button>
            <button className='settingBtn' value={'house'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-house" />
                <div>House</div>
            </button>
            <button className='settingBtn' value={'school'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-school" />
                <div>School</div>
            </button>
            <button className='settingBtn' value={'city'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-city" />
                <div>City</div>
            </button>
            <button className='settingBtn' value={'store'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-store" />
                <div>Store</div>
            </button>
            <button className='settingBtn' value={'Igloo'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-igloo" />
                <div>Igloo</div>
            </button>
            <button className='settingBtn' value={'campground'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-campground" />
                <div>Campground</div>
            </button>
            <button className='settingBtn' value={'dungeon'} onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-dungeon" />
                <div>Dungeon</div>
            </button>
        </div>
    )
}

