import React from 'react';
import './ourGoal.css';


export default function OurGoal() {
    return (
        <div className='goalContainer'>
            <h2>Our Goal</h2>
            <p>The goal of BabbleBot is to provide a safe, accessible, and fun learning experiencing for students as they learn their critical early English skills. 
                We do this by combining modern advancements in artifical intelligence technology with Science of Reading aligned practices to provide a tool that 
                assists students and teachers alike.
            </p>
        </div>
    )
}