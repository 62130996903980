import React, { useState, useEffect } from "react";
import OpenAI from 'openai';
import { firestore } from "../../firebase"; // Ensure you import storage from firebase
import { collection, getDocs, setDoc, doc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import './teacherGenerateStory.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowRightLong, faArrowLeftLong);

const structureTypeMapping = {
  consonantblends: "Consonant Blends",
  shortvowels: "Short Vowels",
  consonants: "Consonants",
  longvowels: "Long Vowels",
  prefixes: "Prefixes",
  suffixes: "Suffixes",
};

export default function TeacherGenerateStory({ setShowGenerateStory, setSelectedStory }) {
  const [topic, setTopic] = useState('');
  const [setting, setSetting] = useState('');
  const [selectedStructures, setSelectedStructures] = useState([]);
  const [structures, setStructures] = useState({});
  const [textLoading, setTextLoading] = useState(false);
  const [openAIOutput, setOpenaiOutput] = useState('');
  const [dropdownValues, setDropdownValues] = useState({});
  const [sentenceCount, setSentenceCount] = useState(1);
  const { teacherDocumentID } = useParams();

  const fetchStructures = async () => {
    try {
      const phonemicStructuresRef = collection(firestore, 'phonemicStructures');
      const structureTypesSnapshot = await getDocs(phonemicStructuresRef);
      let fetchedStructures = {};

      for (const typeDoc of structureTypesSnapshot.docs) {
        const type = typeDoc.id;
        const structuresCollectionRef = collection(firestore, `phonemicStructures/${type}/structures`);
        const structuresSnapshot = await getDocs(structuresCollectionRef);

        fetchedStructures[type] = structuresSnapshot.docs.map(doc => ({
          id: doc.id,
          phonemicTranslation: doc.data().phonemicTranslation,
          englishTranslation: doc.data().englishTranslation,
        }));
      }

      setStructures(fetchedStructures);
    } catch (error) {
      console.error("Error fetching structures:", error);
    }
  };

  useEffect(() => {
    fetchStructures();
  }, []);

  const handleStructureChange = (e, type) => {
    const { value } = e.target;
    if (value && !selectedStructures.includes(value)) {
      setSelectedStructures(prev => [...prev, value]);
      setDropdownValues(prev => ({ ...prev, [type]: '' }));
    }
  };

  const removeSelectedStructure = (id) => {
    setSelectedStructures(prev => prev.filter(structure => structure !== id));
  };

  const handleStorySubmit = async () => {
    if (!topic || !setting || selectedStructures.length === 0) {
      alert("Please provide a topic, setting, and select at least one phonemic structure before generating a story.");
      return;
    }

    setTextLoading(true);

    const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });
    const selected = Object.values(structures).flat().filter(s => selectedStructures.includes(s.id));
    const phonemicTranslations = selected.map(s => s.phonemicTranslation).join(', ');

    const storyPromptTemplate = `
      Please write a short, simple story for kindergarten-aged children that is highly concentrated with the phonemic structures "${phonemicTranslations}".
      The story should be approximately ${sentenceCount} sentences long, set in a ${setting}, and relate to the topic "${topic}".
      Ensure that the story is simple and suitable for young children, without using any complex vocabulary or any commas, quotation marks, apostrophes, or question marks.
    `;

    const storyResponse = await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: storyPromptTemplate }]
    });

    const generatedStory = storyResponse.choices[0].message.content.trim();
    setOpenaiOutput(generatedStory);

    setTextLoading(false);

    // Generate story title based on the generated story
    const titlePromptTemplate = `
      Please create a short and simple title for the following children's story:
      "${generatedStory}". Do not include apostrophe's or quotations around the story title. Just output the title of the story.
    `;
    
    const titleResponse = await openai.chat.completions.create({
      model: 'gpt-4o',
      messages: [{ role: 'user', content: titlePromptTemplate }]
    });
    const storyTitle = titleResponse.choices[0].message.content.trim();

    // Save generated story to Firestore
    const generatedStoryDoc = {
      storyContent: generatedStory,
      storyTitle: storyTitle,
      dateCreated: new Date(),
      phonemicFocus: selected.map(s => ({
        phonemicTranslation: s.phonemicTranslation,
        englishTranslation: s.englishTranslation
      })) // Save both phonemicTranslation and englishTranslation
    };

    const randomDocID = doc(collection(firestore, `users/${teacherDocumentID}/generatedStories`)).id;
    await setDoc(doc(firestore, `users/${teacherDocumentID}/generatedStories/${randomDocID}`), generatedStoryDoc);
    setSelectedStory({ id: generatedStoryDoc.id, ...generatedStoryDoc });
    setShowGenerateStory(false);
  };

  return (
    <div className="teacherStoryGenerationContainer">
      <div className="structureContainer">
        <h2 style={{textAlign: 'center'}}>Story Settings</h2>
        <div className="topicAndSetting">
          <div className="floating">
            <input type="text" id='topic' value={topic} onChange={(e) => setTopic(e.target.value)} required/>
            <label htmlFor='topic'>Topic</label>
          </div>
          <div className="floating">
            <input type="text" id='setting' value={setting} onChange={(e) => setSetting(e.target.value)} required/>
            <label htmlFor='setting'>Setting</label>
          </div>
      </div>
      <div className="storyLength">
          <label>
            Story Length (in sentences): 
          </label>
          <select value={sentenceCount} onChange={(e) => setSentenceCount(Number(e.target.value))}>
              {Array.from({ length: 15 }, (_, i) => i + 1).map(num => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
        </div>
        <h2 style={{textAlign: 'center'}}>Phonemic Sounds</h2>
        <div className="soundDropdownsContainer">
          {Object.keys(structures).map((type) => (
            <div key={type} className="soundDropdowns">
              <h4>{structureTypeMapping[type]}</h4>
              <select
                value={dropdownValues[type] || ''}
                onChange={(e) => handleStructureChange(e, type)}
              >
                <option value="">Select Phonemic Structure</option>
                {structures[type].map((structure) => (
                  <option key={structure.id} value={structure.id}>
                    {structure.phonemicTranslation}, {structure.englishTranslation}
                  </option>
                ))}
              </select>
              <div className="selectedStructures">
                {selectedStructures.map(id => {
                  const structure = Object.values(structures).flat().find(s => s.id === id);
                  if (structure && structures[type].some(s => s.id === id)) {
                    return (
                      <div
                        key={id}
                        className="selectedStructure"
                        onMouseEnter={(e) => e.target.innerHTML = 'X'}
                        onMouseLeave={(e) => e.target.innerHTML = `${structure.phonemicTranslation}, ${structure.englishTranslation}`}
                        onClick={() => removeSelectedStructure(id)}
                      >
                        {structure.phonemicTranslation}, {structure.englishTranslation}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          ))}
          </div>
        <div id="buttonContainer">
          <button className='teacherStoryGenerateBtn' onClick={handleStorySubmit}>Generate</button>
        </div>
      </div>
      {textLoading ? (
        <>
          <h3 style={{ fontSize: '30px', color: '#292F36', textAlign: 'center' }}>Generating your personal story!</h3>
          <div className="lds-ellipsis" style={{margin: 'auto'}}><div></div><div></div><div></div><div></div></div>
        </>
      ) : (
        <>
        </>
      )}
    </div>
  );
}
