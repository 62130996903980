import React, { useState } from "react";
import '../css/blog.css';

import Posts from "../components/blog/posts.jsx";

const Blog = () => {

    return (
        <section className="blogPage">
            <h1>BabbleBot Updates</h1>
            <Posts />
        </section>
    )
}

export default Blog;