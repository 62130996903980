import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog, faCat, faBug, faHippo, faOtter, faCow, faFish, faDragon } from '@fortawesome/free-solid-svg-icons';

import '../../css/componentStyles.css';

library.add( faDog, faCat, faBug, faHippo, faOtter, faCow, faFish, faDragon );

export default function CharacterSelect({ onSelectCharacter }){
    // Function to handle button clicks
    const handleButtonClick = (event) => {
        onSelectCharacter(event.target.value);
    };

    return (
        <>
        <div className='characterSelect' id='characterSelect'>
            <button className='characterBtn' value='dog' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-dog"/>
                <div>Dog</div>
            </button>
            <button className='characterBtn' value='cat' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-cat" />
                <div>Cat</div>
            </button>
            <button className='characterBtn' value='bug' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-bug" />
                <div>Bug</div>
            </button>
            <button className='characterBtn' value='hippo' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-hippo" />
                <div>Hippo</div>
            </button>
            <button className='characterBtn' value='otter' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-otter" />
                <div>Otter</div>
            </button>
            <button className='characterBtn' value='cow' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-cow" />
                <div>Cow</div>
            </button>
            <button className='characterBtn' value='fish' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-fish" />
                <div>
                    Fish
                </div>
            </button>
            <button className='characterBtn' value='dragon' onClick={handleButtonClick}>
                <FontAwesomeIcon className='buttonIcon' icon="fa-solid fa-dragon" />
                <div>
                    Dragon
                </div>
            </button>
        </div>
        </>
    );
}
