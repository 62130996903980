import React from "react";
import '../../css/blog.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(faXmark);

export default function FullBlog( {blogData, selectedBlog, setSelectedBlog }) {
    return (
        <div className="fullBlog">
            <div className="heroSection" style={{backgroundImage: `url(${blogData[selectedBlog].img})`}}>
                <button onClick={(e) => setSelectedBlog(null)}><FontAwesomeIcon icon="fa-solid fa-xmark" className="closeBlogX"/></button>
            </div>
            <div className="titleWrapper">
                    <h2>{blogData[selectedBlog].title}</h2>
                    <div className="tagsContainer">
                        {blogData[selectedBlog].tags.map((tag, index) => (
                            <p key={index}>{tag}</p>
                        ))}
                    </div>
            </div>
            <div className="bodyContent">
                {blogData[selectedBlog].body.map((body, index) => (
                    <p key={index}>{body}</p>
                ))}
            </div>
        </div>
    )
}