import React, { useState } from "react";
import StudentDash from '../../images/studentDashboardUpdated.png';
import CharacterSelect from '../../images/characterSelect.png';
import LessonSession from '../../images/lessonSession.png';
import AnnotateDash from '../../images/annotateDash.png';
import './steps.css';

export default function Steps() {

    const [jobNum, setJobNum] = useState(0);

    const jobs = [
        {
            img: StudentDash,
            bullets: [<li>Students log in using the class code provided by the instructor.</li>, 
                      <li>They can locate and select their name from the class roster.</li>,
                      <li>Once logged in, students are welcomed by BabbleBot.</li>
                    ]
        },
        {
            img: CharacterSelect,
            bullets: [
                <li>Upon logging in, students are presented with their lesson details and a "Go!" button.</li>,
                <li>Clicking the "Go!" button initiates their lesson session, allowing students to choose their character.</li>,
                <li>Next, students select the environment in which they'd like their character to appear.</li>
            ]
        },
        {
            img: LessonSession,
            bullets: [
                <li>After selecting their story components, the story is generated.</li>,
                <li>Upon entering the story, the student's microphone is activated, allowing them to read aloud while progressing through the sentences.</li>,
                <li>Once finished, the student clicks the "Finish" button to submit both their story and recording.</li>
            ]
        },
        {
            img: AnnotateDash,
            bullets: [
                <li>Submitted stories are delivered to the educator dashboard for review.</li>,
                <li>Educators can access both the story and its corresponding recording.</li>,
                <li>By clicking on any mispronounced words, educators can add them to a bank and take notes on the specific pronunciation errors.</li>
            ]
        }
    ]

    function activeStyles (id) {
        document.getElementById('studentDash').classList.remove('active');
        document.getElementById('storyGeneration').classList.remove('active');
        document.getElementById('lessonSession').classList.remove('active');
        document.getElementById('teacherAnnotation').classList.remove('active');

        document.getElementById(id).classList.add('active');
    }

    function showBullets () {
        const bullets = document.getElementById('bullets');
        bullets.style.display = 'block';
    }

    function hideBullets () {
        const bullets = document.getElementById('bullets');
        bullets.style.display = 'none';
    }

    return (
        <section className="stepsSection" id="experience">
            <h2>How it Works?</h2>
            <div className="stepsContainer">
                <div className="stepsTabs">
                    <span>
                        <div className='steps active' id='studentDash' onClick={() => {setJobNum(0); activeStyles('studentDash')}}>Student Dashboard</div>
                        <div className='steps' id='storyGeneration' onClick={() => {setJobNum(1); activeStyles('storyGeneration')}}>Story Generation</div>
                        <div className='steps' id='lessonSession' onClick={() => {setJobNum(2); activeStyles('lessonSession')}}>Lesson Session</div>
                        <div className='steps' id='teacherAnnotation' onClick={() => {setJobNum(3); activeStyles('teacherAnnotation')}}>Teacher Annotation</div>
                    </span>
                </div>

                <div className="stepDesc">
                    <img src={jobs[jobNum].img} id="stepImg" onMouseEnter={(e) => {showBullets()}} onMouseLeave={(e) => {hideBullets()}}/>
                    <div className="bulletContainer" onMouseEnter={(e) => {showBullets()}} onMouseLeave={(e) => {hideBullets()}}>
                        <ul className="job_bullets" id="bullets" style={{display: 'none'}}>
                            {jobs[jobNum].bullets}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}