import React, { useState } from "react";
import '../css/PageNotFound.css';
import BabbleConstruction from '../images/BabbleConstructionNoBkg.png'


const PageNotFound = () => {

    return (
        <section className="pageNotFoundSection">
            <div>
                <h1>404 Error</h1>
                <p>This page is under construction. Please travel back to your previous page.</p>
                <button onclick="javascript:window.history.back();">Go Back</button>
            </div>
            <img src={BabbleConstruction} />
        </section>
    )
}

export default PageNotFound;