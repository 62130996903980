import React, { useState } from 'react';
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import { firestore } from '../../firebase.js'; // Make sure this points to your firebase initialization file
import * as XLSX from 'xlsx';

import '../teacher/teacher.css';

const PhonemicStructureUpload = () => {
    const [file, setFile] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('consonants');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSubmit = async () => {
        if (!file) return;

        try {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = async () => {
                const data = reader.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const dataArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                const phonemicStructureDocRef = doc(firestore, 'phonemicStructures', selectedCategory);
                const docSnap = await getDoc(phonemicStructureDocRef);

                if (!docSnap.exists()) {
                    await setDoc(phonemicStructureDocRef, { structureType: selectedCategory });
                }

                // Loop through columns
                for (let i = 0; i < dataArray[0].length; i++) {
                    const englishTranslation = dataArray[1][i];

                    // Replace invalid characters in English translation for document ID
                    const docId = `${selectedCategory}${englishTranslation.replace(/[^\w\s]/gi, '')}`;

                    const columnDocRef = doc(phonemicStructureDocRef, 'structures', docId);
                    await setDoc(columnDocRef, {
                        phonemicTranslation: dataArray[0][i],
                        englishTranslation: englishTranslation
                    });
                }

                alert('Phonemic structure uploaded successfully!');
            };
        } catch (error) {
            console.error('Error uploading phonemic structure:', error);
            alert('Error uploading phonemic structure. Please try again.');
        }
    };

    return (
        <div className='uploadContainer'>
            <input className='uploadClassInput' type="file" accept=".xlsx" onChange={handleFileChange} />
            <select className='uploadCategorySelect' value={selectedCategory} onChange={handleCategoryChange}>
                <option value="consonants">Consonants</option>
                <option value="longvowels">Long Vowels</option>
                <option value="shortvowels">Short Vowels</option>
                <option value="consonantblends">Consonant Blends</option>
                <option value="suffixes">Suffixes</option>
                <option value="prefixes">Prefixes</option>
            </select>
            <button className='uploadBtn' onClick={handleSubmit}>Upload Phonemic Structure</button>
        </div>
    );
};

export default PhonemicStructureUpload;
