import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout.js";
import Home from "./pages/Home.js";
import Signup from "./pages/Signup.js";
import Tests from "./pages/TestPage.js";
import Signin from "./pages/Signin.js";
import TeacherDash from "./pages/TeacherDash.js";
import StudentDash from './pages/studentDash.js';
import Lesson from './pages/lessonSession.js';
import TeacherSignIn from './pages/teacherSignin.js';
import Classroom from './pages/classroom.js';
import Settings from './pages/settings.js';
import Curriculum from './pages/curriculum.js';
import TeacherLibrary from './pages/teacherLibrary.js';
import Notifications from './pages/notifications.js';
import Blog from './pages/blog.js';
import PageNotFound from './pages/PageNotFound.js';

export default function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            {/*( <Route path="signup" element={<Signup />} /> */}
            
            <Route path="testPage" element={<Tests />} />
            <Route path="blog" element={<Blog />} />
            <Route path="signin" element={<Signin />} />
            <Route path="teacherDash" element={<TeacherDash />} />
            <Route path="teacherDash/classroom/:firstName/:teacherDocumentID/:lastName/:classCode" element={<Classroom />} />
            <Route path="teacherDash/settings/:firstName/:teacherDocumentID/:lastName/:classCode" element={<Settings />} />
            <Route path='teacherDash/curriculum/:firstName/:teacherDocumentID/:lastName/:classCode' element={<Curriculum />} />
            <Route path='teacherDash/teacherLibrary/:firstName/:teacherDocumentID/:lastName/:classCode' element={<TeacherLibrary />} />
            <Route path='teacherDash/notifications/:firstName/:teacherDocumentID/:lastName/:classCode' element={<Notifications />} />
            <Route path="StudentDash" element={<StudentDash />} />
            <Route path="LessonSession" element={<Lesson />} />
            <Route path="teacherSignIn" element={<TeacherSignIn />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
