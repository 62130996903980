import React, { useState } from 'react';
import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from '../../firebase.js'; // Make sure this points to your firebase initialization file
import * as XLSX from 'xlsx';

import '../teacher/teacher.css';

const CurriculumUpload = () => {
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = async () => {
        if (!file) return;

        try {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = async () => {
                const data = reader.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const dataArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                const curriculumName = dataArray[0][0];
                const objectives = dataArray[0].slice(1);

                const curriculumID = `${curriculumName}`;
                const curriculumRef = doc(firestore, 'curriculums', curriculumID);

                await setDoc(curriculumRef, {
                    curriculumName: curriculumName,
                    objectiveCount: objectives.length,
                });

                // Initialize the objectives with required fields
                for (let j = 1; j < objectives.length + 1; j++) {
                    const objNumber = `objective${j}`;
                    const objectiveDocRef = doc(curriculumRef, 'objectives', objNumber);
                    await setDoc(objectiveDocRef, {
                        objectiveNum: j,
                    });
                }

                // Process each row based on the specified order
                await processData(dataArray, curriculumRef);

                alert('Curriculum uploaded successfully!');
            };
        } catch (error) {
            console.error('Error uploading curriculum:', error);
            alert('Error uploading curriculum. Please try again.');
        }
    };

    const processData = async (dataArray, curriculumRef) => {
        for (let i = 1; i < dataArray.length; i++) {
            const row = dataArray[i];
            const rowType = i; // 1 for objective number, 2 for phoneme focus, etc.

            for (let j = 1; j < row.length; j++) {
                const objNumber = `objective${j}`;
                const value = row[j];

                if (value === undefined || value === "") continue; // Skip if field is blank

                const objectiveDocRef = doc(curriculumRef, 'objectives', objNumber);

                // Update based on rowType
                if (rowType === 1) { // Phoneme Focus
                    await setDoc(objectiveDocRef, { phonemeFocus: value }, { merge: true });
                } else if (rowType === 2) { // Objective Notes
                    await setDoc(objectiveDocRef, { objectiveNotes: value }, { merge: true });
                } else if (rowType === 3) { // Curriculum Section
                    await setDoc(objectiveDocRef, { curriculumSection: value }, { merge: true });
                } else if (rowType === 4) { // Type Section
                    await setDoc(objectiveDocRef, { objectiveType: value }, { merge: true });
                }
            }
        }
    };

    return (
        <div className='uploadContainer'>
            <input className='uploadClassInput' type="file" accept=".xlsx" onChange={handleFileChange} />
            <button className='uploadBtn' onClick={handleSubmit}>Upload Curriculum</button>
        </div>
    );
};

export default CurriculumUpload;
