import React from "react";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale, // x-axis
    LinearScale, // y-axis
    PointElement,
    Filler
} from 'chart.js';
import './classStats.css';

import {Doughnut, Line} from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
);



export default function ClassStats (
    classObjectivesKeys,
    classObjectivesValues,
    unitScores,
    unitNames
) {

    const totalStudents = (studentArray) => {
        let total = 0;
        for(let i = 0; i <= studentArray.length; i++){
            total = total + i;
        }
        console.log(total);
        return total;
    }

    totalStudents(classObjectivesKeys.classObjectivesValues);


    const doughnutChartData = {
        labels: classObjectivesKeys.classObjectivesKeys,
        datasets: [{
            label: `Students`,
            data: classObjectivesKeys.classObjectivesValues,
            backgroundColor: ['#38023B', '#BBD5ED', '#CCFFCB', '#FFD700', '#FF6347', '#9370DB', '#87CEEB', '#90EE90', '#F4A460', '#FF69B4'],
            borderColor: ['white'],
            borderWidth: 5,
        }]
    };

    const doughnutChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'center'
            }
        },
        hoverOffset: 10,
        aspectRatio: 1,
        borderRadius: 10,
        animation: {
            animateScale: true
        }
    };

    console.log('classstats unit names', unitNames)

    const lineChartData = {
        labels: unitNames,
        datasets: [{
            label: 'Average Grades per Unit',
            data: [20, 100, 50, 30, 20],
            fill: true,
            borderColor: 'rgb(75, 180, 192)',
            tension: 0.3
        }],
        yAxisID: 'Score',
        xAxisID: 'Objective'
    };

    const lineChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'center'
            }
        },
        aspectRatio: 3/1,
    }


    return (
        <div className="classStatsContainer">
            <div className="unitChart">
                <h3>Objectives Overview</h3>
                <Doughnut
                    data = {doughnutChartData}
                    options={doughnutChartOptions}
                ></Doughnut>             
            </div>

            <div className="horizontalCards">
                <h3>Average Grades</h3>
                <Line
                    data = {lineChartData}
                    options = {lineChartOptions}
                ></Line>
            </div>
        </div>
    )
}