import React, { useEffect, useState } from "react";
import Post from "./post.jsx";
import Pagination from "./pagination.jsx";
import FullBlog from "./fullBlog.jsx";
import '../../css/blog.css';
import { firestore } from "../../firebase";
import { collection, getDocs, updateDoc, doc, getDoc, onSnapshot, setDoc, serverTimestamp, orderBy } from "firebase/firestore";

// Notes for blog inputs
// Images must be 355x200 for best quality

export default function Posts() {

    const [selectedBlog, setSelectedBlog] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);
    const [blogDataObjects, setBlogDataObjects] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const blogCollection = collection(firestore, 'blogs');
                const blogSnapshot = await getDocs(blogCollection);
                const blogData = blogSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    createdAt: serverTimestamp()
                }))
                setBlogDataObjects(blogData);
                console.log(blogData)
            } catch (error) {
                console.error('Error Fetching Blogs');
            }
        }

        fetchBlogs();
    }, [])



    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blogDataObjects.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const handleBlogSelect = (index) => {setSelectedBlog(index)};


    return (
        <section className="blogSection">
            <div className="postsContainer">
                {currentPosts.map((post, index) => (
                    <Post key={index} index={index} post={post} handleBlogSelect={handleBlogSelect} />
                ))}
            </div>

            <Pagination 
                    postsPerPage={postsPerPage}
                    totalPosts={blogDataObjects.length}
                    paginate={paginate}
                    />

            {selectedBlog != null ? (
                <FullBlog blogData={blogDataObjects} selectedBlog={selectedBlog} setSelectedBlog={setSelectedBlog} />
            ) : (
                <div>

                </div>
            )}
        </section>
    )
}