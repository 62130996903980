import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../components/teacher/sideBar";
import TeacherGenerateStory from "../components/teacher/teacherGenerateStory";
import '../css/settings.css';
import TeacherLibraryList from "../components/teacher/teacherLibraryList";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft);

export default function GenerateStory() {
  const { firstName, lastName, teacherDocumentID, classCode } = useParams();
  const [showGenerateStory, setShowGenerateStory] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  const handleButtonClick = () => {
    setShowGenerateStory(prev => !prev);
  };

  return (
    <div style={{ minHeight: '90vh', backgroundColor: 'beige', display: 'flex' }}>
      <Sidebar firstName={firstName} lastName={lastName} teacherDocumentID={teacherDocumentID} classCode={classCode} />
      <div className="settingsPage">
        <div className="libraryHeaderButton">
          <h2 className="pageHeading">My Library</h2>
          <button className="toggleButton libraryCreateStory" onClick={handleButtonClick}>
            {showGenerateStory ? "Back to Library" : "Create Story"}
          </button>
        </div>
        {showGenerateStory 
          ? <TeacherGenerateStory setShowGenerateStory={setShowGenerateStory} setSelectedStory={setSelectedStory} /> 
          : <TeacherLibraryList selectedStory={selectedStory} setSelectedStory={setSelectedStory} />}
      </div>
    </div>
  );
}
