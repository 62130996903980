import React, { useState, useEffect } from 'react';
import StudentBanner from '../components/student/studentBanner';
import StudentNextLesson from '../components/student/studentNextLesson';

// Background Images
import OutsideImg from '../images/streetWithBenchAndLights.jpg';
import SpaceImg from '../images/spaceBackground.jpg';
import ParkImg from '../images/parkScene.jpg';

// Profile Pic Images
import HandsUp from '../images/BabbleHandsUp.png';
import Default from '../images/babblebot.png';
import Check from '../images/BabbleCheck.png';
import Excited from '../images/BabbleExcited.png';
import Sleep from '../images/BabbleSleep.png';
import Wrong from '../images/BabbleX.png';


import { useLocation } from 'react-router-dom';
import { firestore } from '../firebase.js';
import { doc, getDoc, getDocs, collection, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons';

import '../css/studentDash.css';

library.add( faBookOpenReader );

export default function StudentDash() {
    const [curriculumName, setCurriculumName] = useState("");
    const [requiredPassesToProgress, setRequiredPassesToProgress] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [profilePic, setProfilePic] = useState("");
    const [backgroundPic, setBackgroundPic] = useState("");
    const [studentCurrentObjectiveNumber, setStudentCurrentObjectiveNumber] = useState("");
    const [currentObjectiveEnglishTranslations, setCurrentObjectiveEnglishTranslations] = useState(''); 
    const [currentObjectivePhonemicTranslations, setCurrentObjectivePhonemicTranslations] = useState('');
    const [allEnglishTranslations, setAllEnglishTranslations] = useState('')
    const [allPhonemicTranslations, setallPhonemicTranslations] = useState('')
    const [passedLessons, setPassedLessons] = useState('');
    const [previousScore, setPreviousScore] = useState(null);
    const [lastLessonName, setLastLessonName] = useState('');
    const [totalLessons, setTotalLessons] = useState(null);
    const [passCount, setPassCount] = useState();
    const [streak, setStreak] = useState();
    const [gradeLevel, setGradeLevel] = useState();
    const [prevSignOn, setPrevSignOn] = useState();
    const location = useLocation();
    const teacherDocumentID = location.state.teacherID;
    const studentDocumentID = location.state.studentID;
    let currentStreak;


    const [cachedData, setCachedData] = useState({
        englishTranslations: [],
        phonemicTranslations: [],
    });

    useEffect(() => {
        const unsubscribeFunctions = [];
        const studentDocRef = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID);

        const fetchFirstName = async () => {
            const studentDocSnapshot = await getDoc(studentDocRef);

            try {
                const unsubscribe = onSnapshot(studentDocRef, async (studentDocSnapshot) => {
                    if (studentDocSnapshot.exists()) {
                        const userData = studentDocSnapshot.data();
                        setFirstName(userData.firstName);
                        setLastName(userData.lastName);
                        setStudentCurrentObjectiveNumber(userData.currentObjective);
                        setProfilePic(userData.profileImg);
                        setBackgroundPic(userData.backgroundImg);

                        currentStreak = userData.streak || 1;

                        // calculates the steak of the student when signed in on consecutive days                
                        const calculateStreak = async (ref) => {

                            let date = new Date();
                            let prevDate = userData.prevSignOn.toDate();
                            let currDay = date.getDate();
                            let prevDay = prevDate.getDate();

                            if(prevDay + 1 == currDay){
                                console.log('streak has been updated by 1')
                                await updateDoc(ref, {
                                    prevSignOn: date,
                                    streak: currentStreak + 1
                                });
                                setStreak(currentStreak);
                            } else if (prevDay == currDay) {
                                console.log('No Changes in Streak')
                                setStreak(currentStreak);
                            } else {
                                await updateDoc(ref, {
                                    prevSignOn: date,
                                    streak: 1
                                });
                                setStreak(1);
                            }
                        }

                        if (userData.prevSignOn) {
                            await calculateStreak(studentDocRef);
                        } else {
                            console.error('No prev sign on found, setting doc');
                            await updateDoc(studentDocRef, {
                                prevSignOn: new Date(),
                                streak: 1
                            });
                        }
                    }
                });

                unsubscribeFunctions.push(unsubscribe);
            } catch (error) {
                console.error("Error fetching student information: ", error.message);
            }
        };

        fetchFirstName();

        return () => {
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID]);

    useEffect(() => {
        const fetchCurriculumNameAndRequiredPassesToProgress = async () => {
            try {
                const teacherDocRef = doc(firestore, 'users', teacherDocumentID);
                const teacherDocSnapshot = await getDoc(teacherDocRef);
                if (teacherDocSnapshot.exists()) {
                    const teacherData = teacherDocSnapshot.data();
                    setCurriculumName(teacherData.curriculumName);
                    setRequiredPassesToProgress(teacherData.requiredPassesToProgress);
                    setGradeLevel(teacherData.gradeLevel);
                }
            } catch (error) {
                console.error('Error fetching curriculum name:', error);
            }
        };

        fetchCurriculumNameAndRequiredPassesToProgress();
    }, [teacherDocumentID]);

    useEffect(() => {
        const fetchObjectiveData = async () => {
            if (!studentCurrentObjectiveNumber || !curriculumName) return;

            try {
                const currentObjectiveDataRef = doc(firestore, 'curriculums', curriculumName, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                const currentObjectiveDataSnapshot = await getDoc(currentObjectiveDataRef);
                const currentObjectiveData = currentObjectiveDataSnapshot.data();

                if (currentObjectiveData) {
                    const phonemicTranslations = currentObjectiveData.phonemicStructures.map(structure => structure.phonemicTranslation);
                    const phonemicTranslationsString = phonemicTranslations.join(', ').toUpperCase() || 'n/a';

                    const englishTranslations = currentObjectiveData.phonemicStructures.map(structure => structure.englishTranslation);
                    const englishTranslationsString = englishTranslations.join(', ') || 'n/a';

                    setCurrentObjectivePhonemicTranslations(phonemicTranslationsString);
                    setCurrentObjectiveEnglishTranslations(englishTranslationsString);
                } else {
                    setCurrentObjectivePhonemicTranslations('n/a');
                    setCurrentObjectiveEnglishTranslations('n/a');
                }
            } catch (error) {
                console.error('Error fetching objective data:', error);
            }
        };

        fetchObjectiveData();
    }, [studentCurrentObjectiveNumber, curriculumName]);

    useEffect(() => {
        const unsubscribeFunctions = [];

        const previousLessonsData = () => {
            try {
                const previousLessonRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives');
                
                const unsubscribe = onSnapshot(previousLessonRef, async (previousLessonSnapshot) => {
                    if (!previousLessonSnapshot.empty) {
                        const lessonDocs = previousLessonSnapshot.docs;
                        const previousLessonDoc = lessonDocs[lessonDocs.length - 1];
                        const previousLessonDocRef = collection(firestore, 'users', `${teacherDocumentID}`, 'students', `${studentDocumentID}`, 'objectives', `${previousLessonDoc.id}`, 'lessons');
                        
                        // onSnapshot to listen for real-time updates within that collection.
                        const unsubscribeNested = onSnapshot(previousLessonDocRef, (nestedSnapshot) => {
                            let passCount = 0; // Initialize a counter for 'pass' fields equal to true
                            nestedSnapshot.docs.forEach(doc => {
                                const lessonData = doc.data();
                                if (lessonData.pass === true) { // Check if 'pass' field is true
                                    passCount += 1; // Increment count if true
                                }
                            });
                            
                            setPassCount(passCount); // Update state with the latest count
                        });
                        console.log(lessonDocs)
                        unsubscribeFunctions.push(unsubscribeNested);
                    }
                });
        
                // Assuming you have an array unsubscribeFunctions where you store unsubscribe callbacks
                unsubscribeFunctions.push(unsubscribe);
        
            } catch (error) {
                console.log(error);
            }
        };

        previousLessonsData();

        return () => {
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID]);

    useEffect(() => {
        const loadCachedData = () => {
            const englishTranslations = sessionStorage.getItem('englishTranslations');
            const phonemicTranslations = sessionStorage.getItem('phonemicTranslations');
            if (englishTranslations && phonemicTranslations) {
                setCachedData({
                    englishTranslations: JSON.parse(englishTranslations),
                    phonemicTranslations: JSON.parse(phonemicTranslations),
                });
                setIsLoading(false);
                console.log(cachedData)
                return true;
            }
            console.log('did not load cached data');
            return false;
        };

        const englishTranslationsAndPhonemicTranslations = async () => {
            if (loadCachedData()) {
                return;
            }
            if (!studentCurrentObjectiveNumber) return;

            try {
                const fetchedObjectiveData = [];
                const allPhonemicTranslations = [];
                const allEnglishTranslations = [];

                // Fetch objectives including and preceding the selected objective
                for (let i = 1; i <= studentCurrentObjectiveNumber; i++) {
                    const objectiveDocRef = doc(firestore, 'curriculums', `${curriculumName}`, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                    const objectiveDocSnap = await getDoc(objectiveDocRef);
                    if (objectiveDocSnap.exists()) {
                        const data = objectiveDocSnap.data();
                        fetchedObjectiveData.push(data);
                        console.log('read from firebase')
                        setIsLoading(false);
                    }
                }

                if (fetchedObjectiveData.length > 0) {
                    fetchedObjectiveData.forEach(objectiveData => {
                        if (objectiveData.englishTranslations) {
                            allEnglishTranslations.push(...objectiveData.englishTranslations);
                        }
                        if (objectiveData.phonemicTranslations) {
                            allPhonemicTranslations.push(...objectiveData.phonemicTranslations);
                        }
                    });

                    // Use Set to remove duplicates and then update state
                    setCachedData({
                        englishTranslations: [...new Set(allEnglishTranslations)],
                        phonemicTranslations: [...new Set(allPhonemicTranslations)],
                    });

                    console.log(cachedData);

                    // Cache the data for future use
                    sessionStorage.setItem('englishTranslations', JSON.stringify([...new Set(allEnglishTranslations)]));
                    sessionStorage.setItem('phonemicTranslations', JSON.stringify([...new Set(allPhonemicTranslations)]));
                } else {
                    console.log("No objectives found!");
                    setCachedData({ englishTranslations: [], phonemicTranslations: [] });
                }
            } catch (error) {
                console.error("Error fetching objectives data:", error);
                setCachedData({ englishTranslations: [], phonemicTranslations: [] });
            }
        };

        englishTranslationsAndPhonemicTranslations();
    }, [studentCurrentObjectiveNumber]);

    useEffect(() => {
        const unsubscribeFunctions = [];

        const updateTotalLessons = async () => {
            if (!studentCurrentObjectiveNumber) return;

            try {
                const objectiveDocRef = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                const lessonsCollectionRef = collection(objectiveDocRef, 'lessons');
                const lessonsSnapshot = await getDocs(lessonsCollectionRef);
                const totalLessonsCount = lessonsSnapshot.size;

                await updateDoc(objectiveDocRef, { totalLessons: totalLessonsCount });
                setTotalLessons(totalLessonsCount); // Update local state with total lessons count
            } catch (error) {
                console.error("Error updating total lessons count:", error.message);
            }
        };

        updateTotalLessons();

        // Subscribe to changes in lessons collection for real-time updates
        const lessonsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`, 'lessons');
        const unsubscribeLessonSnapshot = onSnapshot(lessonsCollectionRef, updateTotalLessons);
        unsubscribeFunctions.push(unsubscribeLessonSnapshot);

        return () => {
            // Unsubscribe from snapshot listeners when component unmounts
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID, studentCurrentObjectiveNumber]);

    useEffect(() => {
        const unsubscribeFunctions = [];

        const updatePassedLessons = async () => {
            if (!studentCurrentObjectiveNumber) return;

            try {
                const objectiveDocRef = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`);
                const lessonsCollectionRef = collection(objectiveDocRef, 'lessons');
                const lessonsSnapshot = await getDocs(lessonsCollectionRef);
                let passedLessonsCount = 0;

                // Count the number of lessons with 'pass' field set to true
                lessonsSnapshot.forEach(lessonDoc => {
                    const lessonData = lessonDoc.data();
                    if (lessonData.pass === true) {
                        passedLessonsCount++;
                    }
                });

                // Update the 'passedLessons' field in the objective document
                await updateDoc(objectiveDocRef, { passedLessons: passedLessonsCount });
                setPassedLessons(passedLessonsCount); // Update local state with passed lessons count
            } catch (error) {
                console.error("Error updating passed lessons count:", error.message);
            }
        };

        updatePassedLessons();

        // Subscribe to changes in lessons collection for real-time updates
        const lessonsCollectionRef = collection(firestore, 'users', teacherDocumentID, 'students', studentDocumentID, 'objectives', `objective${studentCurrentObjectiveNumber}`, 'lessons');
        const unsubscribeLessonSnapshot = onSnapshot(lessonsCollectionRef, updatePassedLessons);
        unsubscribeFunctions.push(unsubscribeLessonSnapshot);

        return () => {
            // Unsubscribe from snapshot listeners when component unmounts
            unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
        };
    }, [teacherDocumentID, studentDocumentID, studentCurrentObjectiveNumber]);


    const showImgOptions = (element) => {
        let imgOptions = document.getElementById(element);
        imgOptions.style.display = imgOptions.style.display === 'none' ? 'block' : 'none';
    }

    const updateBackground = async (img) => {
        const ref = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID);
        let backgroundSize = document.getElementById('background');

        if (img == '/static/media/parkScene.e54d7a49ce705d5caa74.jpg') {
            backgroundSize.style.backgroundSize = 'contain';
        } else {
            backgroundSize.style.backgroundSize = 'cover';
        }

        await updateDoc(ref, {
            backgroundImg: img,
        })
    }

    const updateProfilePic = async (img) => {
        const ref = doc(firestore, 'users', teacherDocumentID, 'students', studentDocumentID);
        
        await updateDoc(ref, {
            profileImg: img,
        })
    }


    return (
        <div className='studentDashPage' id='background' style={{backgroundImage: `url(${backgroundPic})`, backgroundSize: 'cover'}}>
            {/* Pass the fetched first name to the StudentBanner component 
            <StudentBanner studentName={firstName} />*/}
                <div className='studentNav'>

                    <FontAwesomeIcon className='studentNavIcon' icon="fa-solid fa-image" onClick={(e) => showImgOptions('backgroundSelect')}/>

                    <FontAwesomeIcon className='studentNavIcon' icon="fa-solid fa-user" onClick={(e) => showImgOptions('profilePicSelect')}/>
                
                    <div className='selectionContainer'>
                        <div id='backgroundSelect' style={{display: 'none'}}>
                            <div className='backgroundImgContainer'>
                                <div className='individualImgContainer' onClick={(e) => {updateBackground('/static/media/streetWithBenchAndLights.97abc3de65b8bf1ee6a8.jpg')}}>
                                    <img src={OutsideImg} alt='Street Background'/>
                                    <p>Outside</p>
                                </div>
                                <div className='individualImgContainer' onClick={(e) => {updateBackground('/static/media/spaceBackground.ea3725cc1d29eeee2af8.jpg')}}>
                                    <img src={SpaceImg} alt='Space Background'/>
                                    <p>Space</p>
                                </div>
                                <div className='individualImgContainer' onClick={(e) => {updateBackground('/static/media/parkScene.e54d7a49ce705d5caa74.jpg')}}>
                                    <img src={ParkImg} alt='Park Background'/>
                                    <p>Park</p>
                                </div>
                            </div>
                        </div>
                        <div id='profilePicSelect' style={{display: 'none'}}>
                            <div className='profileImgContainer'>
                                <div className='individualImgContainer' onClick={(e) => {updateProfilePic('/static/media/BabbleHandsUp.9bb7ef5e5db62605b676.png')}}>
                                    <img src={HandsUp} alt='Street Background'/>
                                    <p>Happy</p>
                                </div>
                                <div className='individualImgContainer' onClick={(e) => {updateProfilePic('/static/media/babblebot.d6ea5dffc155cd280cb8.png')}}>
                                    <img src={Default} alt='Street Background'/>
                                    <p>Wink</p>
                                </div>
                                <div className='individualImgContainer' onClick={(e) => {updateProfilePic('/static/media/babblebot.d6ea5dffc155cd280cb8.png')}}>
                                    <img src={Check} alt='Street Background'/>
                                    <p>Check</p>
                                </div>
                                <div className='individualImgContainer' onClick={(e) => {updateProfilePic('/static/media/BabbleExcited.407948975d434931d431.png')}}>
                                    <img src={Excited} alt='Street Background'/>
                                    <p>Excited</p>
                                </div>
                                <div className='individualImgContainer' onClick={(e) => {updateProfilePic('/static/media/BabbleSleep.be5428098172d481d634.png')}}>
                                    <img src={Sleep} alt='Street Background'/>
                                    <p>Sleepy</p>
                                </div>
                                <div className='individualImgContainer' onClick={(e) => {updateProfilePic('/static/media/BabbleX.9e2cd76327a39fc1f36b.png')}}>
                                    <img src={Wrong} alt='Street Background'/>
                                    <p>Wrong</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            
            {isLoading ? ( 
                <div className='loadingContainer' style={{display: 'flex', justifyContent: 'center', alignContent: 'center', minHeight: '300px'}}>
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            ) : (
                <div className='studentDash'>
                    <div className='studentDashLeft'>
                        <img src={profilePic} />
                        <p className='speechBubble'>Hello, {firstName}!</p>
                    </div>
                    <div className='studentDashRight'>
                        <StudentNextLesson 
                            firstName={firstName}
                            lastName={lastName}
                            streak={streak}
                            previousLesson={lastLessonName}
                            previousLessonScore={previousScore}
                            previousLessonAudio=''
                            scoreColor=''
                            teacherDocumentID={teacherDocumentID}
                            studentDocumentID={studentDocumentID}
                            studentCurrentObjectiveNumber={studentCurrentObjectiveNumber}
                            currentObjectiveEnglishTranslations={currentObjectiveEnglishTranslations}
                            currentObjectivePhonemicTranslations={currentObjectivePhonemicTranslations}
                            passedLessons={passCount}
                            totalLessons={totalLessons}
                            requiredPassesToProgress={requiredPassesToProgress}
                            allEnglishTranslations={allEnglishTranslations}
                            allPhonemicTranslations={allPhonemicTranslations}
                            profilePic={profilePic}
                            gradeLevel={gradeLevel}
                            cachedData={cachedData}
                        />

                    </div>
                </div>
            )}
            
        </div>
    );
}
